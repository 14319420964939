/**
 * eslint-disable @next/next/no-img-element
 *
 * @format
 */

/** @format */
"use client";

import { DataTable } from "../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import  { useEffect, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import Layout from "../../layout";
import axios from 'axios';
import { Utils } from "../../../../lib/Custom-Utils";
import {  Member } from "@/lib/types";

type Props = {};
 

const columns: ColumnDef<Member>[] = [
  {
    accessorKey: "full_name",
    header: "Name",
    cell: ({ row }) => {
      const { member_id, first_name, last_name } = row.original;
      return (
        <a href={`./members/${member_id}`} className="flex gap-2 items-center">
          <img
            className="h-10 w-10"
            src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${first_name} ${last_name}`}
            alt="user-image"
          />
          <p>{`${first_name} ${last_name}`}</p>
        </a>
      );
    }
  },
  {
    accessorKey: "birthdate",
    header: "Date de naissance"
  },
  {
    accessorKey: "etablisment",
    header: "Établissement"
  },
  {
    accessorKey: "study_level",
    header: "Niveau d'étude"
  },
  {
    accessorKey: "membership_status.status_name",
    header: "statut d'adhésion"
  },
  {
    accessorKey: "place.place_name",
    header: "lieu d'étude préféré"
  },
  {
    accessorKey: "groups.name",
    header: "groupe",
    cell: ({ row }) => {
      let Groups = "";
      row.original.groups?.forEach(group => {
        if(Groups===""){
          Groups = group.name;
        }else{
          Groups += " | " + group.name;
        }
      });
      return (
        <span>{Groups}</span>
      );
    }
  },
  {
    accessorKey: "payments",
    header: "statut de paiement",
    cell: ({ row }) => {
       
      return (
        <a href={"0"} className="flex gap-2 items-center text-red-600">
         -50 TND
        </a>
      );
    }
  },
  
];



export default function MembersPage({}: Props) {
  const [data, setData] = useState<Member[]>([]); // Initialize with sample data
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Uncomment the following lines when your API is ready
         const response = await axios.get(Utils.getUrlApi()+'members', {
          headers: {
              'Authorization': `Bearer ${token}` // Add Bearer token to the request
          }});
         setData(response.data);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>
      <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="Enfants" />
          <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/members/create', '_self') }}>
          Creer un nouveau
        </button>
        </div>
        <DataTable columns={columns} data={data} />
      </div>
    </Layout>
  );
}
