// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Level } from "../../../../../lib/types";
import { Input } from "../../../../../components/ui/input";
import { Button } from "../../../../../components/ui/button";


  


const LevelList = () => {
  const [levels, setLevels] = useState<Level[] | null>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');
  const columns: ColumnDef<Level>[] = [
    {
      accessorKey: "level_id",
      header: "ID Niveau"
    },
    {
      accessorKey: "level_name",
      header: "Nom Du Niveau"
    },
    {
      accessorKey: "club.club_name",
      header: "Nom Du Club"
    },
    {
      accessorKey: "price",
      header: "Prix Par Trimester",
      cell: ({ row }) => {
        const { price, level_id } = row.original;
  
        // Update the price in the levels array when the input changes
        const handleInputChange = (e: any) => {
          const newPrice = e.target.value;
          setLevels((prevLevels) =>
            prevLevels?.map((level) =>
              level.level_id === level_id ? { ...level, price: newPrice } : level
            )
          );
        };
  
        // Handle the API call when the OK button is clicked
        const handleOkClick = async () => {
          const level = levels?.find((level) => level.level_id === level_id);
          if (!level) return;
  
          try {
            await axios.put(
              `${Utils.getUrlApi()}level/${level_id}/price-change`,
              { price: level.price },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            window.location.reload();
          } catch (error) {
            alert("Failed to update price. Please try again.");
          }
        };
  
        return (
          <div className="flex gap-2">
            <Input
              type="number"
              value={price}
              onChange={handleInputChange}
            />
            <Button onClick={handleOkClick}>OK</Button>
          </div>
        );
      },
    },
  ];
  
  // Main component
  const handlePriceChange = async (price: number, level_id: number) => {
    try {
      await axios.put(
        `${Utils.getUrlApi()}level/${level_id}/price-change`,
        { price },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      window.location.reload();
    } catch (error) {
      alert("Failed to update price. Please try again.");
    }
  };
  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"shared/levels", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
          setLevels(response.data);
    }).catch((r)=>{
        setError("Failed to load levels");
    }).finally(()=>{
        setLoading(false);
    });
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (levels)
  return (
    <Layout>

    <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="List Des Niveaux" />
        </div>
        <DataTable columns={columns} data={levels} />
      </div>
    </Layout>

  );
};

export default LevelList;
