// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Group } from "../../../../../lib/types";

const columns: ColumnDef<Group>[] = [
    {
      accessorKey: "session_id",
      header: "Seance ID"
    },
    {
      accessorKey: "description",
      header: "Description"
    },
    {
      accessorKey: "date",
      header: "Date"
    },
    {
      accessorKey: "start_time",
      header: "Temp Debut"
    },
    {
      accessorKey: "end_time",
      header: "Temp Fin"
    },
    {
      accessorKey: "session_type.type_name",
      header: "Séance Type"
    },
    {
      accessorKey: "session_status.status_name",
      header: "Seance Statue"
    },
    {
      accessorKey: "presence",
      header: "Présence",
      cell: ({ row }) => {
        return (
          <span >x/x</span>
        );
    }
    },
    {
      accessorKey: "groups",
      header: "Groupes",
      cell: ({ row }) => {
        return (
          <>
            {row.original.groups?.map((group, index) => (
              <a 
                key={index} 
                href={`/admin/groups/${group.group_id}`} 
                style={{ marginRight: "8px" }} // optional for spacing between links
              >
                {group.name}
              </a>
            ))}
          </>
        );
      }
    },
    {
      accessorKey: "groups",
      header: "Lieu",
      cell: ({ row }) => {
        return (
          <>
            {row.original.groups?.map((group, index) => (
              <span>
                {group.place?.place_name}
              </span>
            ))}
          </>
        );
      }
    },
    

 
    
  ];
  


const SessionList = () => {
  const [sessions, setSessions] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');


  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"session", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
        setSessions(response.data);
    }).catch((r)=>{
        setError("Failed to load sessions");
    }).finally(()=>{
        setLoading(false);
    });
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>

    <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="List Des Séance" />
        </div>
        <DataTable columns={columns} data={sessions} />
      </div>
    </Layout>

  );
};

export default SessionList;
