// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Club, Group } from "../../../../../lib/types";

const columns: ColumnDef<Club>[] = [
    {
      accessorKey: "club_id",
      header: "ID Club"
    },
    {
      accessorKey: "club_name",
      header: "Nom Du Club"
    },
    {
      accessorKey: "club_levels",
      header: "Les Niveaux Du Club",
      cell: ({ row }) => {
        return (
          row.original.levels.map(level=>(
            <span> | {level.level_name} </span>
          ))
        );
    }
    }
  ];
  


const ClubList = () => {
  const [clubs, setClubs] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');


  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"shared/clubs", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
        setClubs(response.data);
    }).catch((r)=>{
        setError("Failed to load clubs");
    }).finally(()=>{
        setLoading(false);
    });
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>

    <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="List Des Club" />
        </div>
        <DataTable columns={columns} data={clubs} />
      </div>
    </Layout>

  );
};

export default ClubList;
