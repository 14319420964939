import React from 'react';

const DateSelector = ({ selectedDate, onDateChange, handleSecondPart }: any) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Sélectionnez une date</h2>
      <input
        type="date"
        value={selectedDate.toISOString().substring(0, 10)} // Format date to yyyy-mm-dd
        onChange={(e) => onDateChange(new Date(e.target.value))}
        className="border rounded px-2 py-1"
      />
      <button onClick={handleSecondPart} className='btn-primary'>Confirme</button>
    </div>
  );
};

export default DateSelector;
