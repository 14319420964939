import React, { useState } from 'react';
import GroupSelector from '../components/GroupSelector';
import DateSelector from '../components/DateSelector';
import MemberAttendanceTable from '../components/MemberAttendanceTable';
import Layout from '../../../layout';
import axios from 'axios';
import { Utils } from '../../../../../lib/Custom-Utils';
import { Group, Member_Attendance, Session } from '../../../../../lib/types';
import SessionDetails from '../components/SessionDetails';

const PresencePage = () => {
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date()); // Auto-select today's date by default
  const [memberGroup, setmemberGroup] = useState<any[]>([]); // Stores the members of the selected group
  const [selectedSession, setSelectedSession] = useState<Session | null>(null); // Stores the members of the selected group
  const [memberAttendance, setMemberAttendance] = useState<Member_Attendance[]| null>(null); // Stores the members of the selected group
  

  
  const token = localStorage.getItem('token');
  const [firstPart, setFirstPart] = useState(true);
  const [firstSubPart, setFirstSubPart] = useState(false);
  const [secondPart, setSecondPart] = useState(false);
  // Function to handle group selection
  const handleGroupSelect = async (group: Group) => {
    setFirstSubPart(false);
    setmemberGroup([]);
    setSelectedGroup(group);
    // Fetch members for the selected group from an API (use dummy data for now)
    await axios.get(Utils.getUrlApi() + "member-groups/by-group/" + Number(group.group_id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response)=>{
      setmemberGroup(response.data);
    }).finally(()=>{
      setSelectedDate(new Date());
    });
    await axios.post(
      Utils.getUrlApi() + "session/by-date-group-id/",
      { date: selectedDate?.toISOString().split('T')[0],  group_id: group.group_id }, // This is the request body
      {
        headers: { Authorization: `Bearer ${token}` } // Headers go in the third argument (config)
      }
    ).then((response) => {
      if(response.data.length>0){
        setSelectedSession(response.data[0]);
      }
    }).finally(()=>{
      
    });
    setFirstSubPart(true);

  };

  // Function to handle date change
  const handleDateChange = async (date: any) => {
    setSelectedDate(date);
    await axios.post(
      Utils.getUrlApi() + "session/by-date-group-id/",
      { date: date?.toISOString().split('T')[0],  group_id: selectedGroup?.group_id }, // This is the request body
      {
        headers: { Authorization: `Bearer ${token}` } // Headers go in the third argument (config)
      }
    ).then((response) => {
      setSelectedSession(response.data[0]);
      
    }).finally(()=>{
    });
    

  };

  const handleMemberAttendance = async () =>{
    if(selectedSession == null){
      return;
    }
    await axios.get(
      Utils.getUrlApi() + "member-attendances/by-session-id/" + selectedSession.session_id,
      {
        headers: { Authorization: `Bearer ${token}` } // Headers go in the third argument (config)
      }
    ).then((response) => {
      setMemberAttendance(response.data);
    }).finally(()=>{
    });
  }

  const handleSecondPart = async ()=>{
    await handleMemberAttendance();
    if(selectedSession != null){
      setFirstPart(false);
      setSecondPart(true);
    }else{
      alert('invalid !')
    }
  }
  // Function to handle form submission (saving attendance)
  const handleSaveAttendance = (attendanceData: any) => {
     axios.put(
      Utils.getUrlApi() + "member-attendances/save-attendances",
      { session_id: selectedSession?.session_id, updatedMemberAttendance: attendanceData.updatedMemberAttendance}, // This is the request body
      {
        headers: { Authorization: `Bearer ${token}` } // Headers go in the third argument (config)
      }
    ).then((response) => {
      window.location.reload();
    }).finally(()=>{
    });
    // Call the API to save the data
  };

  return (
    <Layout>
        <div className="container mx-auto py-8">
      <h1 className="text-2xl font-bold mb-6">Gestion de la présence</h1>

      {/* Step 1: Select Group */}
      
      {/* Step 2: Select Date (only show if a group is selected) */}
      {firstPart && (
        <>
        <GroupSelector onSelectGroup={handleGroupSelect} />
        {firstSubPart
          && (
            <>
            <DateSelector selectedDate={selectedDate} onDateChange={handleDateChange} handleSecondPart={handleSecondPart} />
            </>
          )
        }
        </>
      )}
      { secondPart  && (
          <>
           <SessionDetails sessionDetails={selectedSession} />
           <MemberAttendanceTable
            memberGroups={memberGroup}
            memberAttendance={memberAttendance}
            onSave={handleSaveAttendance}/>
          </>
        )}
    </div>
    </Layout>
  );
};

export default PresencePage;
