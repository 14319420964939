import { Session } from '../../../../../lib/types';
import React, { useState } from 'react';

const SessionDetails = ( props : any) => {
    const [sessionDetails, setSessionDetails] = useState<Session>(props.sessionDetails);
    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Session Details</h2>
            <p>Date: {sessionDetails.date}</p>
            <p>Start Time: {sessionDetails.start_time}</p>
            <p>End Time: {sessionDetails.end_time}</p>
            <p>Description: {sessionDetails.description}</p>
        </div>
    );
};

export default SessionDetails;
