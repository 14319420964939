// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Payment } from "../../../../../lib/types";

const columns: ColumnDef<Payment>[] = [
    {
      accessorKey: "payment_id",
      header: "ID Payment"
    },
    {
      accessorKey: "registration_fees",
      header: "Prix d'inscription"
    },
    {
      accessorKey: "paid_amount",
      header: "Montant payé"
    },
    {
      accessorKey: "rest_to_pay",
      header: "Reste à payer"
    },
    {
      accessorKey: "Payment_Type.type_name",
      header: "Type de payment"
    }
  ];
  


const PaymentList = () => {
  const [clubs, setClubs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');


  useEffect(() => {
    // Fetch places
    /*axios.get(Utils.getUrlApi()+"shared/clubs", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
        setClubs(response.data);
    }).catch((r)=>{
        setError("Failed to load clubs");
    }).finally(()=>{
        setLoading(false);
    });*/
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>

    <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="List Des Payments" />
        </div>
        <DataTable columns={columns} data={clubs} />
      </div>
    </Layout>

  );
};

export default PaymentList;
