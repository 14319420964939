/**
 * eslint-disable @next/next/no-img-element
 *
 * @format
 */

/** @format */
"use client";

import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Layout from "../../../layout";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/ui/card";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import PageTitle from "../../../../../components/PageTitle";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import { Answer, Club, Group, Member, Member_Attendance, Payment, Question, QuestionAnswerMember, Session, Transaction } from "../../../../../lib/types";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle } from "../../../../../components/ui/dialog";
import { Input } from "../../../../../components/ui/input";
import { Button } from "../../../../../components/ui/button";
import { Calendar } from "../../../../../components/ui/calendar";



// Define columns for DataTable
const AttendancesColumns: ColumnDef<Member_Attendance>[] = [
  {
    accessorKey: "session.groups[0].name",
    header: "Nom Du Groupe",
    cell({ row }) {
      if (row.original.session?.groups && row.original.session?.groups.length > 0) {
        return (
          <a href={"/admin/groups/" + row.original.session?.groups[0].group_id}>{row.original.session?.groups[0].name}</a>
        )
      } else {
        return (
          <>-</>
        )
      }
    },
  },
  {
    accessorKey: "session.description",
    header: "Description",
  },
  {
    accessorKey: "session.date",
    header: "Date",
  },
  {
    accessorKey: "session.start_time",
    header: "Heure de début",
  },
  {
    accessorKey: "session.end_time",
    header: "Fin des temps",
  },
  {
    accessorKey: "attendance_status.status_name",
    header: "Présence de membre"
  },
  {
    accessorKey: "session.session_status.status_name",
    header: "Statue Du Séance"
  },
  {
    accessorKey: "",
    header: "Calculate ?",
    cell({ row }) {
      return (
        <Checkbox ></Checkbox>
      )
    },
  },
];



const sampleQuestions: Question[] = [
  { question_id: 1, label: "What is your favorite subject?" },
  // More sample questions...
];

const sampleAnswers: Answer[] = [
  { answers_id: 1, label: "Math" },
  { answers_id: 2, label: "Science" },
  // More sample answers...
];

const sampleQuestionAnswers: QuestionAnswerMember[] = [
  { question_answer_member_id: 1, question_id: 1, answer_id: 1, member_id: 1 },
  // More sample question answers...
];


export default function MemberById() {
  const [member, setMember] = useState<Member | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [clubs, setClubs] = useState<Club[]>([]);
  const [sessionPage, setSessionPage] = useState<number>(1);
  const [transactionPage, setTransactionPage] = useState<number>(1);
  const [transactionAmount, setTransactionAmount] = useState("");
  const [promoAmount, setPromoAmount] = useState("");
  const [transactionNote, setTransactionNote] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const token = localStorage.getItem('token');

  const handleTransactionDelete = async (transaction_id: number) => {
    const PIN = prompt("PIN :");
    if (PIN === "3002") {
      await axios.delete(Utils.getUrlApi() + 'transactions/'+ transaction_id , {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }
      });
      window.location.reload();
    }
  }
  const transactionColumns: ColumnDef<Transaction>[] = [
    {
      accessorKey: "transaction_date",
      header: "Transaction Date",
      cell: ({ getValue }) => getValue(), // Format date as needed
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: ({ getValue }) => `${getValue()} TND`, // Append currency suffix
    },
    {
      accessorKey: "payment_type.type_name",
      header: "Transaction Type",
      cell: ({ row }) => row.original.payment_type?.type_name || 'Unknown', // Handle nested property
    },
    {
      accessorKey: "note",
      header: "note",
    },
    {
      accessorKey: "transaction_id",
      header: "Supprimer",
      cell: ({ row }) => {
        const { transaction_id } = row.original;
        return (
          <div onClick={()=>{handleTransactionDelete(transaction_id)}} className="flex gap-2 items-center text-red-500">
            Supprimer
          </div>
        );
      }
    },
  ];
  
  // Handle transaction submission
  const handleTransactionSubmit = async () => {
    try {
      await axios.post(Utils.getUrlApi() +'transactions', {
        member_id: member?.member_id,
        amount: transactionAmount,
        transaction_date: selectedDate,
        note: transactionNote,
      }, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }});
        window.location.reload();
      } catch (error) {
      alert("Failed to add transaction.");
    }
  };

  // Handle promo submission
  const handlePromoSubmit = async () => {
    try {
      await axios.post(Utils.getUrlApi() +'payments/add-promo', {
        member_id: member?.member_id,
        promo_amount: promoAmount,
      }, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }});
        window.location.reload();
      } catch (error) {
      alert("Failed to add promo.");
    }
  };
  // Using URLSearchParams to get the ID from query params
  const { id } = useParams();
  const handleDelete = async () => {
    const PIN = prompt("PIN :");
    if (PIN === "3002") {
      await axios.delete(Utils.getUrlApi() + 'members/' + id, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }
      });
      window.open('/admin/members', '_self');
    }
  }
  const itemsPerPage = 2;
  useEffect(() => {
    const fetchData = async () => {
      /*  try {
          // Uncomment the following lines when your API is ready
           const response = await axios.get(Utils.getUrlApi()+'parents');
           setParents(response.data);
        } catch (err) {
          //
          }
  */

      try {
        // Uncomment the following lines when your API is ready
        const response = await axios.get(Utils.getUrlApi() + 'utils/clubs-with-levels', {
          headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
          }
        });
        setClubs(response.data);
      } catch (err) {
        //
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        // Commented out API call for member details
        const response = await axios.get(`${Utils.getUrlApi()}members/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
          }
        });
        setMember(response.data);
        // Simulate fetching member details data
        /* const foundMember = sampleMemberData.find(m => m.member_id === Number(id));
         if (foundMember) {
           setMember(foundMember);
         } else {
           setError('Member not found');
         }*/
      } catch (err) {
        setError('Failed to fetch member details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMemberDetails();
    }
  }, [id]);

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;
  if (!member) return <p className="text-center">Member not found</p>;

  return (
    <Layout>
      <PageTitle title="Member Details" />
      <div className="flex flex-col gap-5 p-5 w-full max-w-5xl mx-auto">
        <Card>
          <CardHeader className="flex justify-between">
            <div>
              <CardTitle className="text-xl font-semibold">
                {`${member.first_name} ${member.last_name}`}
              </CardTitle>
              <h3 className="text-lg text-gray-600">
                Member Details
              </h3>
            </div>
            <div>
              <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/members/edit/' + member.member_id, '_self') }}>
                Modifier
              </button>
              <button onClick={() => { handleDelete() }} className="bg-deep-orange-800 text-white px-2 py-1" >
                Supprimer
              </button>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex gap-4 items-center">
              {member.pic_path_link ? (
                <img
                  className="h-24 w-24 rounded-full object-cover"
                  src={member.pic_path_link}
                  // src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${member.first_name} ${member.last_name}`}
                  alt="member-image"
                />
              ) : (
                <img
                  className="h-24 w-24 rounded-full object-cover"
                  src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${member.first_name} ${member.last_name}`}
                  alt="member-image"
                />
              )}
              <div className="flex flex-col w-full">
                <p className="text-lg font-medium"><strong>Date de naissance:</strong> {member.birthdate}</p>
                <p className="text-lg font-medium"><strong>Établissement:</strong> {member.etablisment}</p>
                <p className="text-lg font-medium"><strong>Niveau d'étude:</strong> {member.study_level}</p>
                <p className="text-lg font-medium"><strong>Lieu d'étude préféré:</strong> {member.place?.place_name}</p>
                <p className="text-lg font-medium"><strong>Sélection du club:</strong> {(member.club_level_selection && member.club_level_selection.length > 0)
                  ? member.club_level_selection.map((clubSelection) => (
                    clubSelection.club?.club_name + " - " + clubSelection.level?.level_name
                  )).join(', ')  // Joins the mapped elements into a single string
                  : "-"
                }</p>
                <p className="text-lg font-medium"><strong>Statut de membre:</strong> {member.membership_status.status_name}</p>
                <p className="text-lg font-medium"><strong>Type d'adhésion:</strong> {member.membership_type.type_name}</p>
                <div className="mt-4">
                  <h3 className="text-lg font-medium">Horaires d'étude préférés</h3>
                  <ul className=" list-inside ml-5">
                    {member.prefered_study_time_member?.map(studyTime => (
                      <li key={studyTime.prefered_study_time_member_id}>
                        <p><strong>Jour:</strong> {studyTime.prefered_study_day}</p>
                        <p><strong>Temp:</strong> {studyTime.prefered_study_time}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        {/* Display Payments */}
      {member.payments && member.payments.length > 0 && (
        <Card className="mt-4">
          <CardHeader>
            <CardTitle className="text-xl font-semibold">Module De Payment</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-2 mb-4">
              {/* Ajoute Une Transaction Button */}
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="bg-black text-white px-2 py-1">Ajoute Une Transaction</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Ajouter Une Transaction</DialogTitle>
                  </DialogHeader>
                  <div className="flex flex-col gap-4">
                    <Input
                      placeholder="Montant de transaction"
                      value={transactionAmount}
                      onChange={(e) => setTransactionAmount(e.target.value)}
                    />
                  <Input
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  placeholder="Date"
                  type="date"
                  />

                    <Input
                      placeholder="Note (facultatif)"
                      value={transactionNote}
                      onChange={(e) => setTransactionNote(e.target.value)}
                    />
                  </div>
                  <DialogFooter>
                    <Button onClick={handleTransactionSubmit}>Ajouter</Button>
                    <Button variant="secondary">Annuler</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Ajoute Un Promo Button */}
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="bg-black text-white px-2 py-1">Ajoute Un Promo</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Ajouter Un Promo</DialogTitle>
                  </DialogHeader>
                  <div className="flex flex-col gap-4">
                    <Input
                      placeholder="Montant de promo"
                      value={promoAmount}
                      onChange={(e) => setPromoAmount(e.target.value)}
                    />
                  </div>
                  <DialogFooter>
                    <Button onClick={handlePromoSubmit}>Ajouter</Button>
                    <Button variant="secondary">Annuler</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>

            <ul>
              {member.payments.map((payment) => (
                <li key={payment.payment_id}>
                  <p className="text-lg">
                    <strong>Prix d'inscription : </strong> {payment.registration_fees} TND
                  </p>
                  <p className="text-lg">
                    <strong>Montant payé : </strong> {payment.paid_amount} TND
                  </p>
                  <p className="text-lg">
                    <strong>Reste à payer : </strong> {payment.rest_to_pay} TND
                  </p>
                  <p className="text-lg">
                    <strong>Promo : </strong> {payment.discount_price} TND
                  </p>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
        {/* Display Group Memberships */}
        {member.groups && member.groups.length > 0 && (
          <Card className="mt-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Groups</CardTitle>
            </CardHeader>
            <CardContent>
              <ul>
                {member.groups.map(group => (
                  <li key={group.group_id}>
                    <a href={"/admin/groups/" + group.group_id} className="text-lg font-medium">{group.name}</a>
                    <p className="text-sm text-gray-600">Max Members: {group.max_members_number}</p>
                    <p className="text-sm text-gray-600">Statue du membre group: {group.member__group[0].member_group_status.status_name}</p>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}

        {/* Display AttendancesColumns */}
        {member.attendances && member.attendances.length > 0 && (
          <Card className="my-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Attendances</CardTitle>
            </CardHeader>
            <CardContent>
              <DataTable
                columns={AttendancesColumns}
                data={member.attendances}
              />
            </CardContent>
          </Card>
        )}


        {/* Display Transactions */}
        {member.payments && member.payments.length > 0 && member.payments[0]?.transactions &&  (
          <Card className="my-4">
            <CardHeader>
              <CardTitle>Transactions</CardTitle>
            </CardHeader>
            <CardContent>
              <DataTable
                columns={transactionColumns}
                data={member.payments[0]?.transactions}
              />
            </CardContent>
          </Card>
        )}
        {/* Display Question Answers */}
        {member.question_answers && member.question_answers.length > 0 && (
          <Card className="mt-4">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Question Answers</CardTitle>
            </CardHeader>
            <CardContent>
              <ul>
                {member.question_answers.map(qa => {
                  const question = sampleQuestions.find(q => q.question_id === qa.question_id);
                  const answer = sampleAnswers.find(a => a.answers_id === qa.answer_id);

                  return (
                    <li key={qa.question_answer_member_id}>
                      <p className="text-lg font-medium">Question: {question?.label}</p>
                      <p className="text-sm text-gray-600">Answer: {answer?.label}</p>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        )}
      </div>
    </Layout>
  );
}
