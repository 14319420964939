import { Utils } from '../../../../../lib/Custom-Utils';
import { member_group_status, member_attendance_status, MembeGroup } from '../../../../../lib/types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const MemberAttendanceTable = ({ memberGroups, memberAttendance = [], onSave }: any) => {
  const [memberGroup, setMemberGroup] = useState<MembeGroup []>(memberGroups); // Handles Member_Group
  const [memberAttendanceData, setMemberAttendanceData] = useState<any[]>(memberAttendance); // Handles Member_Attendance
  const [memberGroupStatus, setMemberGroupStatus] = useState<member_group_status[]>([]);
  const [memberAttendanceStatus, setMemberAttendanceStatus] = useState<member_attendance_status[]>([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Fetch Statut du groupe membre (for each member in the group)
    axios.get(Utils.getUrlApi() + 'shared/member-group-status', {
      headers: { Authorization: `Bearer ${token}` }
    }).then((response) => {
      setMemberGroupStatus(response.data);
    });

    // Fetch Member Statut de présence
    axios.get(Utils.getUrlApi() + 'shared/member-attendance-status', {
      headers: { Authorization: `Bearer ${token}` }
    }).then((response) => {
      setMemberAttendanceStatus(response.data);
    });
  }, []);


  // Handle Member Statut de présence Change (Member_Attendance_Status)
  const handleAttendanceStatusChange = (member_id: any, newStatus: any) => {
    if(memberAttendanceData == null){
      const updatedAttendance = []
      updatedAttendance.push({
        member_id: member_id,
        member_attendance_status_id: newStatus,
      });
      setMemberAttendanceData(updatedAttendance);
    }else{
      const updatedAttendance = memberAttendanceData.map((attendanceData: any) => {
        if (attendanceData.member_id === member_id) {
          return { ...attendanceData, member_attendance_status_id: newStatus };
        }
        return attendanceData;
      });
      // If no attendance exists for the member, we create a new record
      if (!updatedAttendance.some((attendance: any) => attendance.member_id === member_id)) {
        updatedAttendance.push({
          member_id: member_id,
          member_attendance_status_id: newStatus,
        });
      }
      setMemberAttendanceData(updatedAttendance);
    }
  };

  const handleSave = () => {
    // Pass both updated member group and attendance data to the onSave callback
    onSave({
      updatedMemberAttendance: memberAttendanceData,
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Gérer la présence des membres</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">Nom du membre</th>
            <th className="py-2">Statut du groupe membre</th>
            <th className="py-2">Statut de présence</th>
          </tr>
        </thead>
        <tbody>
          {memberGroup.map((groupData: any) => (
            <tr key={groupData.member_id} className="border-b">
              <td className="py-2">{groupData.member.first_name} {groupData.member.last_name}</td>

              {/* Statut du groupe membre (Member_Group_Status) */}
              <td className="py-2">
                <span>{groupData.member_group_status.status_name}</span>
              </td>

              {/* Statut de présence (Member_Attendance_Status) */}
              <td className="py-2">
                {memberAttendanceData ? 
                <select
                className="border rounded px-2 py-1"
               
                onChange={(e) => handleAttendanceStatusChange(groupData.member_id, e.target.value)}
              >
                <option value="-1">Choisir Présence</option>
                {memberAttendanceStatus.map((attStatus) => {
                    const isSelected = memberAttendanceData.some(
                        (memAtt) => Number(memAtt.member_id) === Number(groupData.member_id) && Number(memAtt.member_attendance_status_id) === Number(attStatus.member_attendance_status_id)
                       
                    );
                    return (
                        <option key={attStatus.member_attendance_status_id} value={attStatus.member_attendance_status_id} selected={isSelected}>
                            {attStatus.status_name}
                        </option>
                    );
                })}

              </select>

                :
                <select
                  className="border rounded px-2 py-1"
                 
                  onChange={(e) => handleAttendanceStatusChange(groupData.member_id, e.target.value)}
                >
                  <option value="-1">choisir Présence</option>
                  {memberAttendanceStatus.map((attStatus) => (
                    <option key={attStatus.member_attendance_status_id} value={attStatus.member_attendance_status_id}>
                      {attStatus.status_name}
                    </option>
                  ))}
                </select>
                }
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
        onClick={handleSave}
      >
        Enregistrer les modifications
      </button>
    </div>
  );
};

export default MemberAttendanceTable;
