// src/components/GroupDetail.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Group, Member, Session, SessionStatus, SessionType } from "../../../../../lib/types";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../components/ui/card";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Input } from "../../../../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../../components/ui/select";
import { Button } from "../../../../../components/ui/button";





const GroupDetail = () => {
  const { id } = useParams();
  const [group, setGroup] = useState<Group>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');
  const [members, setMembers] = useState<Member[]>([]);
  const [newMemberId, setNewMemberId] = useState(0);
  const [sessionType, setSessionType] = useState<SessionType[] | null>(null);
  const [sessionStatus, setSessionStatus] = useState<SessionStatus[] | null>(null);
  const [selectedMemberId, setSelectedMemberId] = useState(0);
  const [sessionData, setSessionData] = useState<Session>({
    session_id: 0,
    date: "",
    start_time: "",
    end_time: "",
    description: "",
    session_status_id: 0,
    session_type_id:0
  });
  const handleMemberGroupDelete = async (member_id: number) => {
    const PIN = prompt("PIN :");
    if (PIN === "3002") {
      await axios.delete(Utils.getUrlApi() + 'member-groups/'+ id +'/'+ member_id, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }
      });
      window.location.reload();
    }
  }
  const handleSessionDelete = async (session_id: number) => {
    const PIN = prompt("PIN :");
    if (PIN === "3002") {
      await axios.delete(Utils.getUrlApi() + 'session/' + session_id, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }
      });
      window.location.reload();
    }
  }
  const handleDelete = async () => {
    const PIN = prompt("PIN :");
    if (PIN === "3002") {
      await axios.delete(Utils.getUrlApi() + 'groups/' + id, {
        headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }
      });
      window.open('/admin/members', '_self');
    }
  }
  // Fetch group details and members based on group info
  useEffect(() => {
    const getGroupDetails = async () => {
      try {
        const groupResponse = await axios.get(Utils.getUrlApi() + "groups/" + Number(id), {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGroup(groupResponse.data);

        // Fetch members for the dropdown based on the group's club_id, level_id, and place_id
        const membersResponse = await axios.get(
         Utils.getUrlApi() + `members/by-club-level-place/${groupResponse.data.club.club_id}/${groupResponse.data.level.level_id}/${groupResponse.data.place.place_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setMembers(membersResponse.data);
      } catch (r) {
        setMembers([]);
        //setError("Failed to load group details.");
      } finally {
        setLoading(false);
      }
    };
    axios.get(Utils.getUrlApi()+"shared/session-types").then((response) => {
      setSessionType(response.data);
    });
    axios.get(Utils.getUrlApi()+"shared/session-status").then((response) => {
      setSessionStatus(response.data);
    });

    getGroupDetails();
  }, [id, token]);

const handleAutoMemberAddToGroup  = async () => {
    await axios.post(
      Utils.getUrlApi() + `member-groups/auto-affect-group-members/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    ).then(()=>{
      window.location.reload();

    }).catch((error)=>{
      alert(error.response.data.message);
    });

};

  // Handle adding a member to the group
  const handleAddMember = async () => {
    if (newMemberId) {
      await axios.post(
        Utils.getUrlApi() + `member-groups/affect-group-member/${newMemberId}/${id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      ).then(()=>{
        window.location.reload();

      }).catch((error)=>{
        alert(error.response.data.message);
      });
    }
  };

  // Handle adding a session to the group
  const handleAddSession = async () => {
    await axios.post(Utils.getUrlApi()+`session/`, { ...sessionData, group_id: id }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    alert("Session created successfully!");
  };
// Define columns for DataTable
const sessionColumns: ColumnDef<Session>[] = [
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "date",
    header: "Date",
  },
  {
    accessorKey: "start_time",
    header: "Start Time",
  },
  {
    accessorKey: "end_time",
    header: "End Time",
  },
  {
    accessorKey: "session_type.type_name",
    header: "Type",
  },
  {
    accessorKey: "session_status.status_name",
    header: "Statue",
  },
  {
    accessorKey: "Member_Attendance.member_attendance_status.status_name",
    header: "Member Attendance",
    cell: ({ row }) => row.original.Member_Attendance?.member_attendance_status?.status_name || '-', // Handle nested property

  },
  {
    accessorKey: "session_id",
    header: "Supprimer",
    cell: ({ row }) => {
      const { session_id } = row.original;
      return (
        <div onClick={()=>{handleSessionDelete(session_id)}} className="flex gap-2 items-center text-red-500">
          Supprimer
        </div>
      );
    }
  },
];
const membersColumns: ColumnDef<Member>[] = [
  {
    accessorKey: "full_name",
    header: "Name",
    cell: ({ row }) => {
      const { member_id, first_name, last_name } = row.original;
      return (
        <a href={`/admin/members/${member_id}`} className="flex gap-2 items-center">
          <img
            className="h-10 w-10"
            src={`https://api.dicebear.com/7.x/lorelei/svg?seed=${first_name} ${last_name}`}
            alt="user-image"
          />
          <p>{`${first_name} ${last_name}`}</p>
        </a>
      );
    }
  },
  {
    accessorKey: "study_level",
    header: "Niveau d'étude"
  },
  {
    accessorKey: "membership_status.status_name",
    header: "statut d'adhésion"
  },
  {
    accessorKey: "member_id",
    header: "Status du member group",
    cell: ({ row }) => {
      const { member__group } = row.original;
      if(row.original.member__group && row.original.member__group.length > 0){
        return (
          <>{row.original.member__group[0].member_group_status.status_name}</>
        )
      }else{
        return (
          <> - </>
        )
      }
    }
  },
  {
    accessorKey: "member_id",
    header: "Supprimer",
    cell: ({ row }) => {
      const { member_id } = row.original;
      return (
        <div onClick={()=>{handleMemberGroupDelete(member_id)}} className="flex gap-2 items-center text-red-500">
          Supprimer
        </div>
      );
    }
  },
];


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (group != null) {
    return (




      <Layout>
        <PageTitle title="Detail de group" />
        <div className="flex flex-col gap-5 p-5 w-full max-w-4xl mx-auto">
          <Card>
            <CardHeader className="flex justify-between">
              <div>
                <CardTitle className="text-xl font-semibold">
                  {`${group.name}`}
                </CardTitle>
                <h3 className="text-lg text-gray-600">
                  Group Details
                </h3>
              </div>
              <div>

                <button onClick={() => { handleDelete() }} className="bg-deep-orange-800 text-white px-2 py-1" >
                  Supprimer
                </button>
              </div>
            </CardHeader>
            <CardContent>
              <div className="flex gap-4 items-center">
                <div className="flex flex-col">
                  <p className="text-lg font-medium"><strong>Club:</strong> {group.club?.club_name}</p>
                  <p className="text-lg font-medium"><strong>Niveau :</strong> {group.level?.level_name}</p>
                  <p className="text-lg font-medium"><strong>Lieu :</strong> {group.place?.place_name}</p>
                  <p className="text-lg font-medium"><strong>Statut de group:</strong> Actif</p>
                  <p className="text-lg font-medium"><strong>Type de group:</strong> Normale</p>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* Add Member Card */}
          <Card>
            <CardHeader>
              <CardTitle className="text-xl">Ajouter un Member dans un group</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
                <Select
                 value={String(newMemberId)} 
                 onValueChange={(value: string) => setNewMemberId(Number(value))}
                >
                  <SelectTrigger id="parent-select">
                    <SelectValue placeholder="Sélectionner le Member" />
                  </SelectTrigger>
                  <SelectContent>
                    {members.map((member) => (         
                      <SelectItem  key={member.member_id} value={member.member_id.toString()}>
                      {`${member.first_name} ${member.last_name}`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button onClick={handleAddMember}>Ajouter</Button>
                <Button onClick={handleAutoMemberAddToGroup} className="bg-deep-orange-500">Ajoute Automatique</Button>
              </div>
            </CardContent>
          </Card>

          {/* Add Session Card */}
          <Card>
            <CardHeader>
              <CardTitle className="text-xl">Ajouter une seance dans un Group</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
              <p className="text-base">Date:</p>
                <Input
                  value={sessionData.date}
                  onChange={(e) => setSessionData({ ...sessionData, date: e.target.value })}
                  placeholder="Date"
                  type="date"
                />
              <p className="text-base ">Temp debut:</p>

                <Input
                  value={sessionData.start_time}
                  onChange={(e) => setSessionData({ ...sessionData, start_time: e.target.value })}
                  placeholder="Start Time"
                  type="time"
                />
              <p className="text-base ">Temp fin:</p>

                <Input
                  value={sessionData.end_time}
                  onChange={(e) => setSessionData({ ...sessionData, end_time: e.target.value })}
                  placeholder="End Time"
                  type="time"
                />
              <p className="text-base">Description:</p>

                <Input
                  value={sessionData.description}
                  onChange={(e) => setSessionData({ ...sessionData, description: e.target.value })}
                  placeholder="Description"
                />
              <p className="text-base ">Statue du session:</p>

                <Select
                  value={String(sessionData.session_status_id)} 
                  onValueChange={(value: any) => setSessionData({ ...sessionData, session_status_id: Number(value) })}
                >
                  <SelectTrigger id="parent-select">
                    <SelectValue placeholder="Sélectionner le statue de session" />
                  </SelectTrigger>
                  <SelectContent>
                    {(sessionStatus !== null && sessionStatus.length > 0) ? 
                    sessionStatus.map((session) => (
                      <SelectItem key={session.session_status_id} value={session.session_status_id.toString()}>
                        {`${session.status_name}`}
                      </SelectItem>
                    ))
                    : ""
                    }
                  </SelectContent>
                  <p className="text-base">Type du session:</p>

                </Select>

                <Select
                  value={String(sessionData.session_type_id)} 
                  onValueChange={(value: any) => setSessionData({ ...sessionData, session_type_id: Number(value) })}
                >
                  <SelectTrigger id="parent-select">
                    <SelectValue placeholder="Sélectionner le statue de session" />
                  </SelectTrigger>
                  <SelectContent>
                    {(sessionType !== null && sessionType.length > 0) ? 
                    sessionType.map((session) => (
                      <SelectItem key={session.session_type_id} value={session.session_type_id.toString()}>
                        {`${session.type_name}`}
                      </SelectItem>
                    ))
                    : ""
                    }
                  </SelectContent>

                </Select>
                <Button onClick={handleAddSession}>Ajouter</Button>
              </div>
            </CardContent>
          </Card>


          {/* Display Sessions */}
          {group.sessions && group.sessions.length > 0 && (
            <Card className="my-4">
              <CardHeader>
                <CardTitle className="text-xl font-semibold">Sessions</CardTitle>
              </CardHeader>
              <CardContent>
                <DataTable
                  columns={sessionColumns}
                  data={group.sessions}
                />
              </CardContent>
            </Card>
          )}

          {/* Display Members */}
          {group.members && group.members.length > 0 && (
            <Card className="my-4">
              <CardHeader>
                <CardTitle>Members</CardTitle>
              </CardHeader>
              <CardContent>
                <DataTable
                  columns={membersColumns}
                  data={group.members}
                />
              </CardContent>
            </Card>
          )}

        </div>
      </Layout>
    );
  }

};

export default GroupDetail;
