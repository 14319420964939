// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Transaction } from "../../../../../lib/types";

const columns: ColumnDef<Transaction>[] = [
    {
        accessorKey: "transaction_id",
        header: "ID Transaction"
    },
    {
        accessorKey: "payment_id",
        header: "ID Payment"
    },
    {
        accessorKey: "amount",
        header: "Montant"
    },
    {
        accessorKey: "payment_type",
        header: "Type de payment"
    },
    {
        accessorKey: "num_transfer",
        header: "Numero De Transfer"
    },
    {
        accessorKey: "transaction_date",
        header: "Date de Transaction"
    },


];



const TransactionList = () => {
    const [clubs, setClubs] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const token = localStorage.getItem('token');


    useEffect(() => {
        // Fetch places
       /* axios.get(Utils.getUrlApi() + "shared/clubs", {
            headers: {
                'Authorization': `Bearer ${token}` // Add Bearer token to the request
            }
        }).then((response) => {
            setClubs(response.data);
        }).catch((r) => {
            setError("Failed to load clubs");
        }).finally(() => {
            setLoading(false);
        });*/
    }, []);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Layout>

            <div className="flex flex-col gap-5 w-full">
                <div className="flex justify-between">
                    <PageTitle title="List Des Transaction" />
                </div>
                <DataTable columns={columns} data={clubs} />
            </div>
        </Layout>

    );
};

export default TransactionList;
