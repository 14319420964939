import { Group } from '@/lib/types';
import { Utils } from '../../../../../lib/Custom-Utils';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const GroupSelector = ({ onSelectGroup }:any) => {
  const token = localStorage.getItem('token');
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"groups", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
        setGroups(response.data);
    });
  }, []);

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Sélectionnez un groupe</h2>
      <ul className="space-y-4">
        {groups.map((group) => (
          <li
            key={group.group_id}
            className="border-b pb-2 cursor-pointer hover:bg-gray-100"
            onClick={() => onSelectGroup(group)}
          >
            {group.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupSelector;
