// src/components/GroupList.js
import React, { useEffect, useState } from "react";
import { fetchAllGroups, deleteGroup } from "../services/groupApi";
import axios from "axios";
import { Utils } from "../../../../../lib/Custom-Utils";
import Layout from "../../../layout";
import PageTitle from "../../../../../components/PageTitle";
import { DataTable } from "../../../../../components/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { Group } from "../../../../../lib/types";

const columns: ColumnDef<Group>[] = [
    {
      accessorKey: "name",
      header: "Nom Du Group",
      cell: ({ row }) => {
        const { name, group_id } = row.original;
        return (
          <a href={`./groups/${group_id}`} className="flex gap-2 items-center">
            <p>{`${name}`}</p>
          </a>
        );
    }
    },
    {
      accessorKey: "max_members_number",
      header: "Maximum Membres"
    },
    {
      accessorKey: "place.place_name",
      header: "Place"
    },
    {
      accessorKey: "club.club_name",
      header: "Club"
    },
    {
      accessorKey: "level.level_name",
      header: "Level"
    },
    {
      accessorKey: "Capacité",
      header: "Capacité",
      cell: ({ row }) => {
        const {  members_count, max_members_number } = row.original;
        return (
          <span className={(members_count===max_members_number)? "text-red-500" : ""}>{members_count}/{max_members_number}</span>
        );
    }
    },
 
    
  ];
  


const GroupList = () => {
  const [groups, setGroups] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem('token');


  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"groups", {
        headers: {
            'Authorization': `Bearer ${token}` // Add Bearer token to the request
        }}).then((response) => {
        setGroups(response.data);
    }).catch((r)=>{
        setError("Failed to load groups");
    }).finally(()=>{
        setLoading(false);
    });
  }, []);

  const handleDeleteGroup = async (groupId: number) => {
    try {
      await deleteGroup(groupId);
      setGroups(groups.filter((group :any) => group.group_id !== groupId));
    } catch (error) {
      setError("Failed to delete group");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Layout>

    <div className="flex flex-col gap-5 w-full">
      <div className="flex justify-between">
          <PageTitle title="List Des Groupes" />
          <button className="bg-black text-white px-2 py-1" onClick={() => { window.open('/admin/groups/create', '_self') }}>
          Creer un nouveau
        </button>
        </div>
        <DataTable columns={columns} data={groups} />
      </div>
    </Layout>

  );
};

export default GroupList;
