import { useState, useEffect } from "react";
import {Button} from "../../../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../../components/ui/select";
import { Input } from "../../../../../components/ui/input";
import axios from "axios";
import { Club, Level, Place } from "@/lib/types";
import { Utils } from "../../../../../lib/Custom-Utils";
import  Layout  from "../../../layout";

const CreateGroup = () => {
  const [groupName, setGroupName] = useState("");
  const [maxMembers, setMaxMembers] = useState(0);
  const [places, setPlaces] = useState<Place[]>([]);
  const [clubs, setClubs] = useState<Club[]>([]);
  const [levels, setLevels] = useState<Level[]>([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState<Number | null>(-1);
  const [selectedClubId, setSelectedClubId] = useState<Number | null>(-1);
  const [selectedLevelId, setSelectedLevelId] = useState<Number | null>(-1);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Fetch places
    axios.get(Utils.getUrlApi()+"shared/places").then((response) => {
      setPlaces(response.data);
    });

    // Fetch clubs
    axios.get(Utils.getUrlApi()+"shared/clubs").then((response) => {
      setClubs(response.data);
    });
  }, []);

  // Fetch levels when a club is selected
  useEffect(() => {
    if (selectedClubId) {
      axios
        .get(Utils.getUrlApi()+`shared/levels/${selectedClubId}/by-club`)
        .then((response) => setLevels(response.data));
    }
  }, [selectedClubId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if( !groupName || maxMembers ===0 || selectedPlaceId === -1 || selectedClubId === -1 || selectedLevelId === -1 ){
      alert('error !!')
      return;
    }
    // Submit form logic here
    const payload = {
      name: groupName,
      max_members_number: maxMembers,
      place_id: selectedPlaceId,
      club_id: selectedClubId,
      level_id: selectedLevelId,
    };
    // Handle the API call to create the group
    await axios.post(Utils.getUrlApi()+"groups", payload, {
      headers: {
          'Authorization': `Bearer ${token}` // Add Bearer token to the request
      }});

      window.open('/admin/groups', '_self');
  };
  return (
    <Layout>
      <div className="flex flex-col gap-5 w-full">
      <form onSubmit={handleSubmit}>
      <div className='mt-4 '>
        <label>Nom du goup</label>
        <Input
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          placeholder="Enter group name"
          required
        />
      </div>

      <div className='mt-4 '>
        <label>maximum membres nombre</label>
        <Input
          type="number"
          value={maxMembers}
          onChange={(e) => setMaxMembers(parseInt(e.target.value))}
          placeholder="Enter maximum members"
          required
        />
      </div>

      <div className="mt-4">
        <label>Lieu</label>

        <Select value={String(selectedPlaceId)} onValueChange={(value: string) => setSelectedPlaceId(Number(value))}>
          <SelectTrigger id="parent-select">
              <SelectValue placeholder="Sélectionner la place" />
          </SelectTrigger>
          <SelectContent>
              {places.map((place: any) => (
                  <SelectItem aria-selected={String(place.place_id) === String(selectedPlaceId)} key={place.place_id} value={String(place.place_id)}>
                      {place.place_name }
                  </SelectItem>
              ))}
          </SelectContent>
      </Select>
      </div>

      <div className='mt-4 '>
        <label>Club</label>


        <Select value={String(selectedClubId)} onValueChange={(value: string) => setSelectedClubId(Number(value))}>
          <SelectTrigger id="parent-select">
              <SelectValue placeholder="Sélectionner le club" />
          </SelectTrigger>
          <SelectContent>
              {clubs.map((club: any) => (
                  <SelectItem aria-selected={club.club_id === selectedClubId} key={club.club_id} value={String(club.club_id)}>
                      {club.club_name}
                  </SelectItem>
              ))}
          </SelectContent>
      </Select>
      </div>

      <div className='mt-4 '>
        <label>Level</label>
        {(selectedClubId === null)
            ?  ""
            :
        <Select value={String(selectedLevelId)} onValueChange={(value: string) => setSelectedLevelId(Number(value))}>
          <SelectTrigger id="parent-select">
              <SelectValue placeholder="Sélectionner le level" />
          </SelectTrigger>
          <SelectContent>
              {levels.map((level: any) => (
                  <SelectItem aria-selected={level.level_id === selectedClubId} key={level.level_id} value={String(level.level_id)}>
                      {level.level_name}
                  </SelectItem>
              ))}
          </SelectContent>
      </Select>
 }     
      </div>

      <Button type="submit" className="mt-4">Create Group</Button>
    </form>
      </div>
    </Layout>
  );
};

export default CreateGroup;
